import axios from 'axios';

const api_url = 'https://admin.bottax.tech/api'


export async function loginUser(username, password) {
    try {
      const response = await axios.post(`${api_url}/login`, {
        username,
        password
      });
      return response.data;
    } catch (error) {
      console.error('Ошибка авторизации:', error.response ? error.response.data : error.message);
      return { success: false, message: 'Ошибка авторизации' };
    }
  }
