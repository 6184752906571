import React from 'react';
import './MessagesModal.css';
import MessageItem from './MessageItem';

const MessagesModal = ({ show, onClose, messages, selectedUser }) => {
    if (!show) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Переписка с {selectedUser.username}</h2>
                <div className="messages">
                    {messages.length > 0 ? (
                        messages.map((message) => (
                            <MessageItem
                                key={message.id}
                                message={message}
                                isSelf={message.from_id !== selectedUser.user_tg_id}
                            />
                        ))
                    ) : (
                        <p>Нет сообщений</p>
                    )}
                </div>
                <button className="close-button" onClick={onClose}>Закрыть</button>
            </div>
        </div>
    );
};

export default MessagesModal;
