// Users.js
import React, { useState, useEffect } from 'react';
import './Users.css';
import { getMessages, getUsers } from './api';
import { updateUser } from './api';
import { sendMessageToUsers } from './api';
import MessagesModal from '../messages/MessagesModal';

const Users = ({logOut}) => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [filters, setFilters] = useState({ username: '', status: '', source: '' });

    const [selectedUser, setSelectedUser] = useState(null);
    const [newStatus, setNewStatus] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');

    const [messages, setMessages] = useState([]);
    const [showMessagesModal, setShowMessagesModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getUsers();
            setUsers(data);
            setFilteredUsers(data);
        };
        fetchData();
    }, []);

    // const handleSort = (key) => {
    //     console.log('handleSort', key)
    //     let direction = 'ascending';
    //     if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    //         direction = 'descending';
    //     }
    //     setSortConfig({ key, direction });

    //     const sortedUsers = [...filteredUsers].sort((a, b) => {
    //         if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
    //         if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
    //         return 0;
    //     });
    //     setFilteredUsers(sortedUsers);
    // };

    const handleSort = (key) => {
        console.log('handleSort', key);
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedUsers = [...filteredUsers].sort((a, b) => {
            let aValue = a[key];
            let bValue = b[key];

            // Проверяем, является ли ключ 'date_reg'
            if (key === 'date_reg') {
                aValue = new Date(aValue).getTime();
                bValue = new Date(bValue).getTime();
            } else {
                // Для других ключей, используем стандартное сравнение
                if (typeof aValue === 'string') aValue = aValue.toLowerCase();
                if (typeof bValue === 'string') bValue = bValue.toLowerCase();
            }

            if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
            if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setFilteredUsers(sortedUsers);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSendMessage = async () => {
        try {
            await sendMessageToUsers(message, file);
            alert('Сообщение отправлено!');
            setShowModal(false);
            setMessage('');
            setFile(null);
        } catch (error) {
            alert(error.message);
        }
    };


    const handleRowClick = (user) => {
        console.log('handleRowClick')
        setSelectedUser(user);
        setNewStatus(user.status); // Предустанавливаем статус пользователя в модальном окне
    };

    const handleStatusChange = (e) => {
        setNewStatus(e.target.value);
    };

    // const handleSave = () => {
    //     // updateUser({ ...selectedUser, status: newStatus });
    //     updateUser({ userId: selectedUser.id, newStatus });
    //     setSelectedUser(null); // Закрываем модалку после сохранения
    // };

    const handleSave = async () => {
        const updatedUser = await updateUser({ userId: selectedUser.id, newStatus });

        if (updatedUser) {
            // Обновляем статус в `users` или `filteredUsers`
            setFilteredUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.id === selectedUser.id ? { ...user, status: newStatus } : user
                )
            );
            setSelectedUser(null); // Закрываем модалку после сохранения
        }
    };


    //   const handleFilterChange = (e) => {
    //     const { name, value } = e.target;
    //     console.log('name, value', name, value)
    //     setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));

    //     const filtered = users.filter((user) =>
    //       Object.keys(filters).every((filterKey) =>
    //         user[filterKey].toString().toLowerCase().includes(filters[filterKey].toLowerCase())
    //       )
    //     );
    //     setFilteredUsers(filtered);
    //   };


    // const handleFilterChange = (e) => {
    //     const { name, value } = e.target;

    //     // Обновляем фильтры в состоянии
    //     setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));

    //     // Фильтруем пользователей с учетом новых значений
    //     const filtered = users.filter((user) =>
    //         (!name || name !== 'username' || user.username.toLowerCase().includes(value.toLowerCase())) &&
    //         (!name || name !== 'status' || user.status.toLowerCase().includes(value.toLowerCase())) &&
    //         (!name || name !== 'source' || user.source.toLowerCase().includes(value.toLowerCase()))
    //     );

    //     setFilteredUsers(filtered);
    // };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value)

        // Обновляем фильтры в состоянии
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));


        // Фильтруем пользователей с учетом новых значений
        const filtered = users.filter((user) => {
            const matchesUsername = name !== 'username' || user.username.toLowerCase().includes(value.toLowerCase()) || value === '';
            const matchesStatus = name !== 'status' || user.status.toLowerCase().includes(value.toLowerCase());
            const matchesSource = name !== 'source' || user.source.toLowerCase().includes(value.toLowerCase());

            // Проверяем фильтрацию по dateFrom и dateTo
            const userDateReg = new Date(user.date_reg).toISOString().split('T')[0];

            const matchesDateFrom = (name === 'dateFrom' ? userDateReg >= value : userDateReg >= filters.dateFrom) || !filters.dateFrom; // Используем value для dateFrom
            const matchesDateTo = (name === 'dateTo' ? userDateReg <= value : userDateReg <= filters.dateTo) || !filters.dateTo;

            // console.log('handleFilterChange dates', userDateReg, matchesDateFrom, matchesDateTo)

            console.log('handleFilterChange', matchesDateFrom, matchesDateTo, matchesUsername , matchesStatus , matchesSource)

            return matchesUsername && matchesStatus && matchesSource && matchesDateFrom && matchesDateTo;
        });

        setFilteredUsers(filtered);
    };


    const handleOpenMessages = async () => {
        try {
            const userMessages = await getMessages(selectedUser.user_tg_id); // Передаем ID пользователя
            setMessages(userMessages);
            setShowMessagesModal(true);
        } catch (error) {
            console.error("Ошибка при загрузке сообщений:", error);
        }
    };

    const handleLogOut = async () => {
        localStorage.removeItem('authData');
        logOut()
    }

    return (
        <div className="table-container">
            {/* <h1>Users</h1> */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>Пользователи: <span className='q_users'>{` Всего: ${users.length}    Отфильтровано: ${filteredUsers.length}`}</span></h1>
                <button onClick={() => setShowModal(true)}>Рассылка</button>
                <button onClick={handleLogOut}>Выйти</button>
            </div>
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Отправить сообщение пользователям</h2>
                        <textarea
                            placeholder="Введите сообщение"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            rows="4"
                            style={{ width: '100%' }}
                        ></textarea>
                        <label>
                            <input type="file" onChange={handleFileChange} />
                        </label>
                        {file && <p>Выбран файл: {file.name}</p>}
                        <button onClick={handleSendMessage}>Отправить</button>
                        <button onClick={() => setShowModal(false)}>Закрыть</button>
                    </div>
                </div>
            )}

            {/* <div className="filter-container">
                <label>
                    Username:
                    <input
                        type="text"
                        name="username"
                        value={filters.username}
                        onChange={handleFilterChange}
                        placeholder="Filter by username"
                    />
                </label>
                <label>
                    Status:
                    <input
                        type="text"
                        name="status"
                        value={filters.status}
                        onChange={handleFilterChange}
                        placeholder="Filter by status"
                    />
                </label>
                <label>
                    Source:
                    <input
                        type="text"
                        name="source"
                        value={filters.source}
                        onChange={handleFilterChange}
                        placeholder="Filter by source"
                    />
                </label>
            </div> */}

            <div className="filter-container">
                <label>
                    Имя:
                    <input
                        type="text"
                        name="username"
                        value={filters.username}
                        onChange={handleFilterChange}
                        placeholder="Фильтр по имени"
                    />
                </label>
                <label>
                    Статус:
                    <input
                        type="text"
                        name="status"
                        value={filters.status}
                        onChange={handleFilterChange}
                        placeholder="Фильтр по статусу"
                    />
                </label>
                <label>
                    Источник:
                    <input
                        type="text"
                        name="source"
                        value={filters.source}
                        onChange={handleFilterChange}
                        placeholder="Фильтр по источнику"
                    />
                </label>
                <label>
                    Дата начало:
                    <input
                        type="date"
                        name="dateFrom"
                        value={filters.dateFrom}
                        onChange={handleFilterChange}
                    />
                </label>
                <label>
                    Дата конец:
                    <input
                        type="date"
                        name="dateTo"
                        value={filters.dateTo}
                        onChange={handleFilterChange}
                    />
                </label>
            </div>


            <table className="users-table">
                <thead>
                    <tr>
                        <th onClick={() => handleSort('id')}>ID</th>
                        <th onClick={() => handleSort('username')}>Имя</th>
                        <th onClick={() => handleSort('user_tg_id')}>TG ID</th>
                        <th onClick={() => handleSort('status')}>Статус</th>
                        <th onClick={() => handleSort('qq')}>Запросы</th>
                        {/* <th onClick={() => handleSort('status_dialog')}>Status Dialog</th> */}
                        <th onClick={() => handleSort('source')}>Источник</th>
                        <th onClick={() => handleSort('date_reg')}>Дата регистрации</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredUsers.map((user) => (
                        <tr key={user.id} onClick={() => handleRowClick(user)}>
                            <td>{user.id}</td>
                            <td>{user.username}</td>
                            <td>{user.user_tg_id}</td>
                            <td>{user.status}</td>
                            <td>{user.qq}</td>
                            {/* <td>{user.status_dialog}</td> */}
                            <td>{user.source}</td>
                            <td>{user.date_reg ? user.date_reg.slice(0, 10) : ''}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {selectedUser && !showMessagesModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Карточка пользователя</h2>
                        <p><strong>ID:</strong> {selectedUser.id}</p>
                        <p><strong>Имя:</strong> {selectedUser.username}</p>
                        <p><strong>TG ID:</strong> {selectedUser.user_tg_id}</p>
                        <p><strong>Источник:</strong> {selectedUser.source}</p>
                        <label>
                            Статус:
                            <select value={newStatus} onChange={handleStatusChange}>
                                <option value="default">Default</option>
                                <option value="manager">Manager</option>
                                <option value="unlimited">Unlimited</option>
                            </select>

                        </label>
                        <div className='cntr_btn'>
                            <button onClick={handleSave}>Сохранить</button>
                            <button onClick={() => setSelectedUser(null)}>Закрыть</button>
                            <button onClick={handleOpenMessages}>Переписка</button>
                        </div>
                    </div>
                </div>
            )}

            <MessagesModal
                show={showMessagesModal}
                onClose={() => setShowMessagesModal(false)}
                messages={messages}
                selectedUser={selectedUser}
            />

        </div>
    );
};

export default Users;

