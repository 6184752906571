import React from 'react';
import './MessageItem.css';

const MessageItem = ({ message, isSelf }) => {
    return (
        <div className={`message-container ${isSelf ? 'other' : 'self'}`}>
            <div className="message-bubble">
                <strong>{isSelf ? 'Вы' : message.username}:</strong> {message.content}
            </div>
        </div>
    );
};

export default MessageItem;

